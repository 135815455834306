@font-face {
  font-family: 'e-Ukraine';
  src: url('/src/assets/fonts/e-Ukraine-Regular.woff2') format('woff2'),
    url('/src/assets/fonts/e-Ukraine-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'e-Ukraine';
  src: url('/src/assets/fonts/e-Ukraine-Medium.woff2') format('woff2'),
    url('/src/assets/fonts/e-Ukraine-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'e-Ukraine';
  src: url('/src/assets/fonts/e-Ukraine-Bold.woff2') format('woff2'),
    url('/src/assets/fonts/e-Ukraine-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
  box-sizing: border-box !important;
  font-family: 'e-Ukraine', sans-serif !important;
}

*::-webkit-scrollbar {
  width: 1px;
}

*::-webkit-scrollbar-track {
  background: #f1f1f1;
}

*::-webkit-scrollbar-thumb {
  background-color: #d2cbd7;
  border-radius: 20px;
}
*::-webkit-scrollbar-thumb:hover {
  background: #4b4b58;
  border-radius: 20px;
  font-family: 'e-Ukraine', sans-serif !important;
}

:root {
  --fs-1: 1.5rem;
  --fs-2: 1.25rem;
  --fs-3: 1rem;
  --fs-4: 0.85rem;
  --fs-5: 0.75rem;
  --fs-6: 0.7rem;
  --fs-7: 0.65rem;
  --fs-8: 0.5rem;
  --lg-fs-1: 1.75rem;
  --lg-fs-2: 2rem;
  --lg-fs-3: 3rem;
  --lg-fs-4: 4rem;
  --lg-fs-5: 5rem;
}

html,
body {
  margin: 0 !important;
  padding: 0;
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-size: clamp(0.5rem, 0.4rem + 0.3vw, 1.5rem); */
  font-size: clamp(0.75rem, 0.4rem + 0.3vw, 1.05rem);
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

#root {
  width: 100%;
  height: 100%;
  flex: 1 0 auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*:focus {
  outline: 2px solid #0A8FF5;
  outline-offset: 4px;
  transition: outline 0.3s ease, outline-offset 0.3s ease;
}

html,
body,
body div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
}

button {
  cursor: pointer;
  outline: none;
  background-color: transparent;
  border: none;
  padding: 0;
}

.required {
  color: #ff0101;
}

.formErrorContainer {
  font-size: var(--fs-5);
  line-height: 120%;
  color: #ff3333;
}

.buttonsContainer {
  display: flex;
}

.buttonsContainer > div {
  margin-left: 16px;
}

.buttonsContainer > div:first-child {
  margin-left: 0;
}

body.modal-open {
  overflow: hidden;
}

svg:not(:root) {
  overflow: visible;
}

[data-tooltip]:hover::after {
  content: attr(data-tooltip);
  display: block;
  position: absolute;
  padding: 0.35em;
  border-radius: 5px;
  background-color: #555;
  transition: ease-in-out 0.3s;
  color: #fff;
  font-size: var(--fs-5);
}
